import React from 'react';
import '../index.scss';
import SignInForm from './signInForm';
import SignInInfo from './signInInfo';
function SignIn (props){
  return(
    <div>
      <div className='container flex'>
        <div 
        className="flex-50 sm-display-none"
        >
          <SignInInfo/>
        </div> 
        <div 
          className="flex-50 sm-flex-100"
        >
          <SignInForm 
          />
        </div>
      </div>
    </div>  
  )
}


export default SignIn;
