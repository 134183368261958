import {
  connect,
} from 'react-redux';

import SignIn from '../presentational/signIn';

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);
