import { createLogicMiddleware } from 'redux-logic';
import arrLogic from '../logics';

const logicMiddleware = createLogicMiddleware(arrLogic);

/**
 * middle to provide redux Thunk support for async actions.
 */
const middleware = [logicMiddleware];

export default middleware;

