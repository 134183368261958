import React from "react";

export default function RepeatGridSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="193.506"
      height="138.311"
      viewBox="0 0 193.506 138.311"
    >
      <defs>
        <clipPath id="clip-path">
          <path fill="none" d="M0 0H193.506V138.311H0z"></path>
        </clipPath>
      </defs>
      <g
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="1.1"
        clipPath="url(#clip-path)"
        data-name="Repeat Grid 1"
        opacity="0.3"
      >
        <g data-name="Group 7669" transform="translate(-2.494 -2.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(25.506 -2.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(53.506 -2.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(81.506 -2.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(109.506 -2.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(137.506 -2.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(165.506 -2.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(-2.494 32.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(25.506 32.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(53.506 32.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(81.506 32.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(109.506 32.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(137.506 32.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(165.506 32.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(-2.494 67.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(25.506 67.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(53.506 67.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(81.506 67.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(109.506 67.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(137.506 67.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(165.506 67.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(-2.494 102.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(25.506 102.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(53.506 102.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(81.506 102.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(109.506 102.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(137.506 102.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
        <g data-name="Group 7669" transform="translate(165.506 102.5)">
          <path
            d="M3.004 3v15.33a3.256 3.256 0 003.533 3.532h9.357a1.558 1.558 0 001.039-1.5 1.387 1.387 0 00-1.039-1.4H6.306a.621.621 0 01-.635-.633V4.218a1.119 1.119 0 011.176-.995 1.184 1.184 0 011.223.995v12.89"
            data-name="Path 2936"
          ></path>
          <path
            d="M25.056 32.311V16.978a3.255 3.255 0 00-3.531-3.533h-9.358a1.559 1.559 0 00-1.041 1.5 1.388 1.388 0 001.041 1.4h9.59a.62.62 0 01.633.633v14.111a1.118 1.118 0 01-1.176 1 1.181 1.181 0 01-1.221-1v-12.89"
            data-name="Path 2937"
          ></path>
        </g>
      </g>
    </svg>
  );
}

