import React, { useEffect, useRef, useState } from 'react';
import {
  LightBulbSVG,
  Path1SVG,
  RepeatGridSVG
} from '../../../assets';
import appConstants from '../../../constants/appConstants';
const comments = appConstants.comments;

function SignInInfo(){
  
  const timeoutRef = useRef()
  const [commentIndex, setCommentIndex] = useState(0);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  
  useEffect(()=>{
      resetTimeout();
      timeoutRef.current =  setTimeout(() => {
      setCommentIndex((prevState) => {
        return prevState === comments.length -1 ? 0 : prevState + 1; 
      })
    },3000)

    return () => {
      resetTimeout();
    };
  },[commentIndex])

  return(
    <div 
          className="info-container relative height-100"
        >
          <RepeatGridSVG />
          <div className='center'>
            <div className='info-content relative'>
              <LightBulbSVG />
              <h2 className='comments-header'>
                Did you know?
              </h2>
              <div className='slideshow'>
                <div className="slideshowSlider slide-anim"
                  style={{ 
                    transform: `translateX(${-commentIndex * 33.33}%)`,
                    transition: commentIndex === 0 ? `all .0s ease` :  ""
                  }}
                >
                  {comments.map((comment, index) => (
                    <div 
                      className='slide'
                      key={index}
                      // style=
                    >
                      {comment}
                    </div> 
                  ))}
                </div>
                <div 
                  className='dots'>
                  {comments.map((_, index) => (
                    <div 
                      className={`dot slide-anim cursor-pr ${index === commentIndex ? "active-dot":''}`}
                      key={index}
                      onClick = {()=>{
                        setCommentIndex(index)
                      }}
                      style = {{
                        transition: commentIndex === 0 ? `all .0s ease` :  ""
                      }}
                    >  
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <div 
                  className='path-img1 absolute'
                >
                  <Path1SVG/>
                </div>
                <div 
                  className='path-img2 absolute rotate-180'
                >
                  <Path1SVG/>
                </div>
              </div>
            </div>
          </div>
        </div>
  )
}


export default SignInInfo;
