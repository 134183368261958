import axios from 'axios';
import appConstants from '../constants/appConstants'
let sid = '';
   if(global.window.LTD && typeof global.window.LTD !== "undefined"){
     sid = global.window.LTD.getSid()
   }else {
     console.log("LTD not defined, continue only if this local environment otherwise something is breaking!");
     sid = appConstants.sid;
   }

export const ltAxios = axios.create({
    timeout: 10000, 
    withCredentials: false
});

ltAxios.interceptors.request.use(
  (request) => {
   request.headers.common.Authorization = sid;  
   return request
  }
);
