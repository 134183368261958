import {ltAxios as axios} from './httpInterceptor';
import { baseUrl } from '../constants/config';

const fixedHeaders = {};

const get = async (url, headers = {}, queryParams = {}) => {
  try {
    const result = await axios({
      // baseURL: baseUrl,
      url: url,
      params: queryParams,
      method: "GET",
      headers: {
        ...headers
      }
    });

    return result.data;
  } catch (error) {
    throw error;
  }
}

const post = async (url, headers = {}, body = {}) => {
  try {
    const result = await axios({
      // baseURL: baseUrl,
      url: url,
      method: "POST",
      headers: {
        ...fixedHeaders,
        ...headers
      },
      data: body
    });

    return result.data;
  } catch (error) {
    throw error;
  }
}

const put = async (url, headers = {}, body = {}) => {
  try {
    const result = await axios({
      // baseURL: baseUrl,
      url: url,
      method: "PUT",
      headers: {
        ...fixedHeaders,
        ...headers
      },
      data: body
    });

    return result.data;
  } catch (error) {
    throw error;
  }
}

export default {
  get,
  post,
  put
}
