import React from "react";

export default function LightBuldSVG(){
  return(
    <svg id="Group_7670" data-name="Group 7670" xmlns="http://www.w3.org/2000/svg" width="40.505" height="40.505" viewBox="0 0 40.505 40.505">
      <path id="Path_2940" data-name="Path 2940" d="M0,0H40.505V40.505H0Z" fill="none"/>
      <path id="Path_2941" data-name="Path 2941" d="M14.081,29h1.733V20.564H19.19V29h1.733a9.693,9.693,0,0,1,2.937-5.531c.191-.206,1.4-1.463,1.548-1.642a10.126,10.126,0,1,0-15.814,0c.145.181,1.362,1.44,1.549,1.644A9.7,9.7,0,0,1,14.081,29Zm.046,3.375v1.688h6.751V32.378ZM6.96,23.94a13.5,13.5,0,1,1,21.08,0c-1.046,1.3-3.787,3.372-3.787,5.9v4.219a3.375,3.375,0,0,1-3.375,3.375H14.127a3.375,3.375,0,0,1-3.375-3.375V29.847C10.751,27.315,8.009,25.246,6.96,23.94Z" transform="translate(2.751 1.376)" fill="#fff"/>
    </svg>
  )
}

