import React from "react";

export default function Path1SVG(){
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="147.253" height="197.146" viewBox="0 0 147.253 197.146">
      <path id="Path_2939" data-name="Path 2939" d="M12950.391,1483.315V1327.965s2.719-35.8-35.781-35.8h-94.828a15.8,15.8,0,0,0-10.547,15.174,14.06,14.06,0,0,0,10.547,14.205h97.172s6.414,0,6.414,6.417v142.977s-.9,10.083-11.914,10.083a11.962,11.962,0,0,1-12.367-10.083V1340.336" transform="translate(-12806.234 -1289.169)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="6"/>
    </svg>
  )
}

