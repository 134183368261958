import { useEffect, useState } from "react";
import CryptoJS from 'crypto-js';
import http from '../../../utils/http';
import ApiConstants from '../../../constants/apiConstants';

import {
  LimeTrayLogo
} from '../../../assets';
import appConstants from "../../../constants/appConstants";

export default function SignInForm(props){
  const initialUserState = {
    username: '',
    password: '',
    errors: '',
    otp: ''
  };
  
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [user, setUser] = useState(initialUserState);
  const [isOtpFieldVisible, setIsOtpFieldVisible] = useState(false);
  let {username, password, errors, otp} = user;

  const qs = getQueryVariable();
  let errorMessage = qs.get('error');
  let redirectUri = qs.get('redirect');

  useEffect(()=>{    
    if(errorMessage && errorMessage !== ''){
      try {
        errorMessage = atob(errorMessage);
      } catch (error) {
        errorMessage = error
      }
      setUser((user) => {
        return { ...user, errors: errorMessage };
      });
    }
    if(redirectUri){
      setUser((user) => {
        return { ...user, redirectUri: redirectUri };
      });
    }
  },[errorMessage,redirectUri])

  function getQueryVariable(){
		const map = new Map();
        const query = window.location.search.substring(1);        
        const vars = query.split("&");        
        for (let i=0;i < vars.length;i++) {
                var pair = vars[i].split("=");                
                map.set(pair[0],pair[1])
         }
        return map
  }

  const validateFields = () => {
    const  {
      ifUserNameEmpty,
      ifPasswordEmpty,
      ifUserNameHasEnoughChar,
      ifPasswordHasEnoughChar,
      ifOtpEmpty,
      ifOtpHasEnoughChar,
    } = appConstants.validationMessage;
    if(!username.length){
      return {isValid : false, message : ifUserNameEmpty};
    }
    if(!password.length){
      return {isValid : false, message : ifPasswordEmpty};
    }
    if(isOtpFieldVisible && !otp.length){
      return {isValid : false, message : ifOtpEmpty};
    }
    if(username.length < 4){
      return {isValid : false, message : ifUserNameHasEnoughChar};
    }
    if(password.length < 4){
      return {isValid : false, message : ifPasswordHasEnoughChar};
    }
    if(isOtpFieldVisible && otp.length !== 6){
      return {isValid : false, message : ifOtpHasEnoughChar};
    }
    return {isValid: true, message:''}
  }

  function getChecksum(username, password){
    var hash = CryptoJS.SHA256(password);
    hash = CryptoJS.HmacSHA256(username.toString().toLowerCase(), hash.toString().toLowerCase());
    return hash.toString(CryptoJS.enc.Hex);
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser((user) => {
      return { ...user, [name]: value };
    });
  };

  const renderOTPField = () => (
    <div className="flex-column margin-top-20">
      <label 
        className="font-16 font-bolder"
      >
        * OTP
      </label>
      <input 
        onChange = {handleChange}
        type = "number" 
        placeholder = "Enter OTP"
        name="otp"
        value = {otp}
      />
    </div>
  )

  const checkIsMFAActivated = async (username) => {
    return await http.post(ApiConstants.CHECK_IS_MFA_ACTIVATED.URL, {}, {
      username,
    })
  }

  const sendMfaSecret = async () => {
    return await http.post(ApiConstants.SEND_MFA_SECRET.URL, {}, {
    })
  }

  const handleLostDevice = async () => {    
    try {
      const res = await sendMfaSecret();
      if(res.status === 200) {
        setUser((user) => {
          return { ...user, errors: 'Secret has been sent to your registered email!'};
        });
      }
    } catch (response) {
      setUser((user) => {
        return { ...user, errors: 'Some error occured!'};
      });
    }
  }

  const handleSubmit = async(event) => {
    event.preventDefault();
    const {isValid, message} = validateFields(); 
    if(!isValid){
      setUser((user) => (
         { ...user, errors: message}
      ));
      return event.preventDefault();
    }
    try {
      let result = false;
      if(!isOtpFieldVisible){
        let res = await checkIsMFAActivated(username);
        result = res.result;
      }
      if(result && !isOtpFieldVisible) {
        setIsOtpFieldVisible(true);
        setUser((user) => {
          return { ...user, errors: ''};
        });
        return;
      }
      let passwordField = document.getElementById('password');
      passwordField.setAttribute("type","password");
      passwordField.setAttribute("name","userChecksum");
      passwordField.value = getChecksum(username, password);
      event.target.submit();
    } catch (response) {
        let message="Some Error Occured!"
      if (response.response && response.response.status === 404){ 
          message=response.response.data;
      }
       setUser((user) => ({ ...user, errors: message}));
      }
  }

   const {
    title,
    subTitlePrimary,
    subTitleSecondary
   } = appConstants.loginPageMessages;
  return(
    <div className="sign-in-form">
      <div className='brand'>
        <img className="limetrayLogo" src={LimeTrayLogo} />
      </div>
      <h1 className="sm-font-35 margin-top-24">
        {title}
      </h1>
      <p className="margin-24 font-16 margin-top-24">
        {subTitlePrimary}
      </p>
      <p className="font-16 margin-top-20">
        {subTitleSecondary}
      </p>
      <form onSubmit={handleSubmit} method="post" action="/api/v1/login">
        <fieldset>
          <div className="flex-column margin-top-20">
            <label 
              className="font-16 font-bolder"
            >
              * Username
            </label>
            <input 
              onChange = {handleChange}
              type = "text" 
              placeholder = "Enter your username"
              name="username"
              value = {username}
            />
          </div>
          <div className="flex-column margin-top-20 relative">
            <label 
              className="font-16 font-bolder"
            >
              * Password
            </label>
            <input 
              onChange = {handleChange}
              type={isPasswordVisible ? "text" : "password"} 
              placeholder="Enter your password" 
              name="password"
              value={password}
              id="password"
            />
            <input 
              className="show-button cursor-pr"
              onClick={()=>{
                setIsPasswordVisible(!isPasswordVisible);
              }}
              type='button'
              value={isPasswordVisible ? "Hide" : "Show"}
            />
          </div>
          {isOtpFieldVisible && renderOTPField()}
          {/* {isOtpFieldVisible && (
            <div className="text-align-end ">
              <button 
                className="cursor-pr lost-device-button"
                type="button"
                onClick={handleLostDevice}
              > 
                Lost my device ?
              </button>
            </div>
          )} */}
          <input 
            id="redirect"
            name="redirect"
            type="hidden"
            value={redirectUri}
            spellCheck="false"
          />
        </fieldset>
        {/* <fieldset className="flex align-center flex-start margin-20">
          <input 
            type="checkbox" 
          />
          <label>Remember me</label>
        </fieldset> */}
        <div className="margin-20 errors">{errors}</div>
        <fieldset>
          <button 
            className="action-btn brd-radius font-16 cursor-pr"
            type="submit"
          > 
            Login
          </button>
        </fieldset>
        <p 
          className="font-16 margin-top-24 sm-text-center"
        >
          <span>Don't have an account ? No worries, </span>
          <a className="email-link" href="mailto:contactus@limetray.com"> 
            contactus@limetray.com  
          </a>
          <span> to get started</span>
        </p>
      </form>
    </div>
  )
}
