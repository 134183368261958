const appConstants = {
  comments: ['Creating an offer is very simple. Just head to Offer Manager > Offer and set up exclusive offers for your customers!',
  'If you run out of stock for an item, you can quickly turn it off. Just go to Product On-Off and set its availability with one click!',
  'Continuously track the performance of your Brand and its outlets and take pro decisions. Just head to Merchant Dashboard > Analytics Dashboard and view different trends.'
  ],
  validationMessage: {
    ifUserNameEmpty: "Username can not be empty!",
    ifPasswordEmpty: "Password can not be empty!",
    ifOtpEmpty: "OTP can not be empty!",
    ifUserNameHasEnoughChar: "Username should be more than 3 character!",
    ifPasswordHasEnoughChar: "password should be more than 3 character!",
    ifOtpHasEnoughChar: "OTP should be of 6 character!",
  },
  loginPageMessages: {
    title: "Welcome to LimeTray",
    subTitlePrimary:"It's good to see you again!",
    subTitleSecondary: " Type your login information and, we'll take you to your dashboard right away."
  },
  sid :'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJicmFuZFVzZXJJZCI6MTA4NTYyMTYsInVzZXJNb2JpbGUiOiI5NzE2MDM2MzM2IiwiaXNhIjoxNjY1NDA1NTg0OTExLCJicmFuZElkIjoiMCIsInVzZXJGdWxsTmFtZSI6IkFuZWVzaCBEZXZhc3RoYWxlIiwidXNlckVtYWlsIjoiYW5lZXNoQGxpbWV0cmF5LmNvbSIsInRva2VuVHlwZSI6ImlkIiwidXNlcklkIjoiNzdhNjc4NTYtYWYzYi0xMWU2LTg4ZjAtMDI4MjVmMTA0OGE1In0.-VVGk8HK6Ih9Ac7_S6Sj1jyXf10ZUy9JfBNTKYYrtCI'

}

export default appConstants;
