import AllConstants from "../constants/actions";
import ActionConstants from '../constants/actions';

const initialState = {
  isMFAActivated: false,
  isMFAActivatedFetched: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
  switch (action.type) {
    case ActionConstants.UsersConstants.CHECK_IS_MFA_ACTIVATED_INIT : {
      return {
        ...state,
        error:'',
      }
    }
    case ActionConstants.UsersConstants.CHECK_IS_MFA_ACTIVATED_SUCCESS : {
      console.log(action.payload, "pp")
      return {
        ...state,
        isMFAActivated: action.payload,
        isMFAActivatedFetched: true,
      }
    }
    case ActionConstants.UsersConstants.CHECK_IS_MFA_ACTIVATED_ERROR : {
      return {
        ...state,
        isMFAActivated : false,
        error: action.payload,
      }
    }
    default:
    return state;
  }
}

