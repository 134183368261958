const API_OBJECT = {
  CHECK_IS_MFA_ACTIVATED: {
    URL: "/api/v1/mfa/is-activated",
    METHOD: "POST",
  },
  SEND_MFA_SECRET: {
    URL:"/api/v1/mfa/send-secret",
  },
}

export default API_OBJECT;
