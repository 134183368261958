import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';


import './App.scss';
import SignIn  from './views/Users';
function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route exact path="/" element={<SignIn/>} />
        </Routes>
      </Router>
    </Provider>  
  );
}

export default App;
